@import url(https://rsms.me/inter/inter.css);
html {
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", system-ui, -apple-system, BlinkMacSystemFont,
      Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
      Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }
}

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html body {
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  font-size: 16px;
  color: #171e26;
}

html code,
html code.inline-code {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

html .ant-form-item-label > label {
  color: hsla(212, 25%, 12%, 0.7);
  margin-top: 4px;
}

html .ant-form-item {
  margin-bottom: 12px;
}

html .ant-input,
html .ant-input-affix-wrapper {
  background-color: #f4f7fa;
  border-color: transparent;
  border-radius: 6px;
  transition: 0.2s ease background-color, 0.2s ease border-color;
  padding: 8px 16px;
}

html .ant-input:focus,
html .ant-input-affix-wrapper:focus,
html .ant-input-affix-wrapper:focus-within {
  background-color: white;
  border-color: hsl(197, 71%, 52%);
  box-shadow: none;
}

html .ant-input:hover:not(:focus),
html .ant-input-affix-wrapper:hover:not(:focus-within),
html .ant-input-affix-wrapper:hover:not(:focus-within) .ant-input {
  background-color: hsl(210 38% 99% / 1);
  border-color: transparent;
  box-shadow: none;
}

html .ant-input-affix-wrapper:hover .ant-input::-webkit-input-placeholder, html .ant-input-affix-wrapper:focus-within .ant-input::-webkit-input-placeholder {
  opacity: 0.8;
}

html .ant-input-affix-wrapper:hover .ant-input:-ms-input-placeholder, html .ant-input-affix-wrapper:focus-within .ant-input:-ms-input-placeholder {
  opacity: 0.8;
}

html .ant-input-affix-wrapper:hover .ant-input::-ms-input-placeholder, html .ant-input-affix-wrapper:focus-within .ant-input::-ms-input-placeholder {
  opacity: 0.8;
}

html .ant-input-affix-wrapper:hover .ant-input::placeholder,
html .ant-input-affix-wrapper:focus-within .ant-input::placeholder {
  opacity: 0.8;
}

html .ant-input::-webkit-input-placeholder {
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  text-align: center;
  font-family: "Inter var", "Inter", system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

html .ant-input:-ms-input-placeholder {
  -ms-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  text-align: center;
  font-family: "Inter var", "Inter", system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

html .ant-input::-ms-input-placeholder {
  -ms-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  text-align: center;
  font-family: "Inter var", "Inter", system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

html .ant-input::placeholder {
  transition: opacity 0.2s ease;
  text-align: center;
  font-family: "Inter var", "Inter", system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

html .header-search-bar {
  cursor: inherit;
  transition: width 0.2s ease-in-out;
  width: 270px;
}
html .header-search-bar:focus-within {
  width: 500px;
}

html .header-search-bar .ant-input-search {
  padding: 0;
}

html .header-search-bar .ant-input-affix-wrapper > input.ant-input {
  padding: 5px 16px;
  font-size: 14px;
}

html .ant-input-search .ant-input::-webkit-input-placeholder {
  text-align: left;
}

html .ant-input-search .ant-input:-ms-input-placeholder {
  text-align: left;
}

html .ant-input-search .ant-input::-ms-input-placeholder {
  text-align: left;
}

html .ant-input-search .ant-input::placeholder {
  text-align: left;
}

html .header-search-bar:not(.relationship-search) .ant-input::-webkit-input-placeholder {
  word-spacing: 500px;
}

html .header-search-bar:not(.relationship-search) .ant-input:-ms-input-placeholder {
  word-spacing: 500px;
}

html .header-search-bar:not(.relationship-search) .ant-input::-ms-input-placeholder {
  word-spacing: 500px;
}

html .header-search-bar:not(.relationship-search) .ant-input::placeholder {
  word-spacing: 500px;
}

html .header-search-bar:focus-within .ant-input::-webkit-input-placeholder {
  word-spacing: normal;
}

html .header-search-bar:focus-within .ant-input:-ms-input-placeholder {
  word-spacing: normal;
}

html .header-search-bar:focus-within .ant-input::-ms-input-placeholder {
  word-spacing: normal;
}

html .header-search-bar:focus-within .ant-input::placeholder {
  word-spacing: normal;
}

html
  .header-search-bar
  span.anticon.anticon-search.ant-input-search-icon:before {
  content: "⌘ K";
  border: 1px solid #ccc;
  position: absolute;
  display: flex;
  letter-spacing: -1px;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  right: 22px;
  height: 22px;
  border-radius: 5px;
  margin-top: 5px;
  font-family: "Inter var";
  color: rgba(0, 0, 0, 0.22);
  background-color: #f4f7fa;
  z-index: 100;
  right: -3px;
  opacity: 1;
  transition: opacity 0.2s ease, color 0.2s ease;
}

html
  body.not-mac
  .header-search-bar
  span.anticon.anticon-search.ant-input-search-icon:before {
  content: "Ctrl+K";
  letter-spacing: 0;
}

html
  .header-search-bar:hover
  span.anticon.anticon-search.ant-input-search-icon:before {
  color: rgba(0, 0, 0, 0.45);
}

html
  .header-search-bar:focus-within
  span.anticon.anticon-search.ant-input-search-icon:before {
  opacity: 0;
}

html .ant-popover-inner-content,
html .ant-dropdown,
html .ant-dropdown-menu-item,
html .ant-dropdown-menu-submenu-title {
  color: #171e26;
}

html input.graph-search-column-filter {
  background-color: white;
  border: none;
}

html input.graph-search-column-filter::-webkit-input-placeholder {
  text-align: left;
}

html input.graph-search-column-filter:-ms-input-placeholder {
  text-align: left;
}

html input.graph-search-column-filter::-ms-input-placeholder {
  text-align: left;
}

html input.graph-search-column-filter::placeholder {
  text-align: left;
}

html .codex-editor__redactor {
  padding-bottom: 0 !important;
}

html .codex-editor .ce-toolbar {
  display: none;
}

html .codex-editor .ce-inline-toolbar {
  pointer-events: none;
}

html .codex-editor .ce-inline-toolbar.ce-inline-toolbar--showed {
  pointer-events: all;
}

@media (min-width: 651px) {
  html .codex-editor--narrow .codex-editor__redactor {
    margin-right: 0;
  }

  html .codex-editor--narrow .ce-block--focused {
    margin-right: 0;
    padding-right: 0;
  }
}

html .editorjs-container:focus-within .eager-placeholder {
  visibility: hidden;
}

html .no-loader .codex-editor__loader {
  display: none;
}

html .collapsed-rte {
  white-space: nowrap;
  overflow: hidden;
}

html .collapsed-rte.one-line > div {
  overflow: hidden;
}

html .collapsed-rte .ce-block:not(:first-of-type) {
  display: none;
}

html .collapsed-rte .ce-block:first-of-type .ce-paragraph.cdx-block {
  overflow: hidden;
  text-overflow: ellipsis;
}

html .ce-paragraph[data-placeholder]:empty::before {
  cursor: text;
  transition: 0.2s ease opacity;
}

html
  .codex-editor--empty
  .ce-block:first-child
  .ce-paragraph[data-placeholder]:empty:focus::before {
  opacity: 0.4;
}

html
  .codex-editor--empty
  .ce-block:first-child
  .ce-paragraph[data-placeholder]:empty:hover:not(:focus)::before {
  opacity: 0.7;
}

html .ce-block b {
  font-weight: 600;
}

html .onHoverParent .visibleOnParentHover:not(.alwaysVisible) {
  opacity: 0;
  transition: opacity 0.2s ease;
}

html .onHoverParent:hover .visibleOnParentHover,
html .onHoverParent .visibleOnParentHover:focus,
html .onHoverParent .visibleOnParentHover:focus-within {
  opacity: 1;
}

html .onHoverParent:hover .hideOnParentHover {
  display: none;
}

html
  .onHoverParent:not(:hover)
  .defaultCollapsed:not(:focus):not(:focus-within) {
  display: none;
}

html
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: "";
}

html .ant-tooltip {
  max-width: none;
}

html .ant-dropdown,
html .ant-tooltip,
html .ant-tooltip * {
  animation-duration: 0s !important;
}

html .ant-dropdown-menu .search-box .ant-input-search.ant-input-affix-wrapper {
  border-radius: 0;
  border: none;
}

html
  .ant-dropdown-menu
  .search-box:not(:last-child)
  .ant-input-search.ant-input-affix-wrapper {
  border-bottom: 1px solid #e8e8e8;
}

html .ant-dropdown-menu {
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
}

html .ant-input-search-icon::before {
  border-left: none;
}

html .search-box + .ant-dropdown-menu-item {
  margin-top: 36px;
}

.g6-minimap {
  position: absolute;
  bottom: 20px;
  left: 20px;
  box-shadow: rgba(15, 15, 15, 0.037) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.05) 0px 3px 6px, rgba(15, 15, 15, 0.1) 0px 9px 24px;
  background-color: rgba(255, 255, 255, 0.95);
}

.g6-minimap-viewport {
  border: 2px solid rgb(46, 170, 220) !important;
  background-color: rgba(46, 170, 220, 0.07);
}

